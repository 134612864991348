export const roTranslation = {
  components: {
    Breadcrumbs: {
      search: "Căutare piese",
      orders: "Lista comenzilor",
      order: "Comanda",
      createOrder: "Comandă nouă",
      profile: "Profilul meu",
    },
    Cart: {
      cart: "Coș",
      showNetPrice: "Arata prețuri nete",
      showProDiscountPrice: "Arata prețuri cu reducere",
      orderSentError: "Comanda NU a fost trimisă",
      cartIsEmpty: "Coșul este gol",
      finishOrder: "Finalizează comanda",
      clearCart: "Șterge coșul",
    },
    CreateOrderForm: {
      name: "Nume",
      email: "Email",
      phoneNumber: "Telefon",
      clientNotes: "Comentarii la comanda",
      adminNotes: "Comentarii pentru admin",
      paid: "Achitat",
      status: "Status",
      discount: "Reducere",
      send: "Tremite",
      disclaimer:
        "După trimitere, managerul vă va contacta pentru a discuta despre disponibilitatea mărfurilor, timpii de livrare și plata comenzii.",
    },
    GoogleImages: {
      noImages: "Nu au fost găsite imagini",
    },
    OrderSendConfirmation: {
      successTitle: "Comanda a fost trimisă",
      errorTitle: "Comanda NU a fost trimisă",
      successMessage:
        "Comanda a fost trimisă cu succes către manager. Veți fi contactat în scurt timp.",
      closeAndCleanCart: "Închideți și ștergeți coșul",
      close: "Închide",
    },
    ProductList: {
      brand: "Marca",
      partNumber: "Part Number",
      name: "Nume",
      qty: "Cantitate",
      price: "Preț",
      netPrice: "Preț Net",
      proDiscountPrice: "Cu reducere",
      add: "Adaugă",
      remove: "Șterge",
      subTotal: "Subtotal",
      discount: "Reducere",
      total: "Total",
      paid: "Achitat",
      remainingPayment: "Plata rămasă",
      notFound: "Nu s-a găsit nimic",
      qtyInput: "Cantitate",
      sum: "Suma",
      netPriceSum: "Suma Net",
      proDiscountPriceSum: "Cu reducere",
      searchSomething: "Folosiți formularul pentru a căuta piese",
      similarPartNumber:
        "Atenţie! Nu este exact ceea ce cauți. Este parțial similar cu interogarea dvs. Verifică-l de două ori.",
    },
    SearchForm: {
      brand: "Producător",
      partNumber: "Part Number",
    },
    SignInDialog: {
      title: "Intră",
      email: "Email",
      password: "Parola",
      signInButton: "Conectează-te",
      signUpButton: "Crează un cont nou",
      signInSuccess: "Autentificat cu succes",
      signInError: "Autentificare eșuată",
    },
    SignUpDialog: {
      title: "Înregistrare",
      username: "Nume de utilizator",
      email: "Email",
      password: "Parola",
      rePassword: "Confirmarea parolei",
      phoneNumber: "Număr de telefon",
      signInButton: "Intra",
      signUpButton: "Înregistrare",
      signUpSuccess: "Înregistrare reușită",
      signUpSuccessHelper:
        "Confirmați-vă adresa de e-mail folosind linkul din scrisoare",
      signUpError: "Eroare de înregistrare",
    },
    Header: {
      orderHistory: "Istoria comenzilor",
      logOut: "Iesire",
    },
  },
  enums: {
    OrderStatusEnum: {
      paymentRequired: "Așteaptă achitare",
      pending: "Așteaptă comanda",
      ordered: "Comandat",
      inProgress: "In Progress",
      delivered: "Livrat",
      finished: "Finisat",
      dontOrder: "NU comanda",
      null: "",
    },
  },
  pages: {
    CreateOrderPage: {
      contactInformation: "Date de contact",
      productsInCart: "Produse in coș",
      showNetPrice: "Arata prețuri nete",
      showProDiscountPrice: "Arata prețuri cu reducere",
    },
    OrderListPage: {
      createdAt: "Data creării",
      orderedItems: "Piese comandate",
      total: "Preț",
      status: "Status",
      createInvoice: "Creați o factură",
    },
    ViewOrderPage: {
      name: "Nume",
      email: "Email",
      phoneNumber: "Telefon",
      clientNotes: "Comentarii",
      createdAt: "Data creării",
      updatedAt: "Data actualizării",
      paid: "Achitat",
      remainingPayment: "Plata rămasă",
      status: "Status",
      orderedProducts: "Produse comandate",
      showNetPrice: "Arata prețuri nete",
      createInvoice: "Creați o factură",
    },
  },
};

export const enTranslation = {
  components: {
    Breadcrumbs: {
      search: "Search",
      orders: "Order list",
      order: "Order",
      createOrder: "Create Order",
      profile: "My Profile",
    },
    Cart: {
      cart: "Cart",
      showNetPrice: "Show net prices",
      showProDiscountPrice: "Show discount prices",
      orderSentError: "The order WASN'T sent",
      cartIsEmpty: "Cart is empty",
      finishOrder: "Finish order",
      clearCart: "Clear cart",
    },
    CreateOrderForm: {
      name: "Name",
      email: "Email",
      phoneNumber: "Phone",
      clientNotes: "Notes",
      adminNotes: "Admin Notes",
      paid: "Paid",
      status: "Status",
      discount: "Discount",
      send: "Send",
      disclaimer:
        "After sending, the manager will contact you to discuss the availability of goods, delivery times and payment for the order.",
    },
    GoogleImages: {
      noImages: "No images found",
    },
    OrderSendConfirmation: {
      successTitle: "The order was sent",
      errorTitle: "The order WASN'T sent",
      successMessage:
        "The order was sent successfully. The manager will contact you soon",
      closeAndCleanCart: "Close and clean the cart",
      close: "Close",
    },
    ProductList: {
      brand: "Brand",
      partNumber: "Part Number",
      name: "Name",
      qty: "Qty",
      price: "Price",
      netPrice: "Net Price",
      proDiscountPrice: "With discount",
      add: "Add",
      remove: "Remove",
      subTotal: "Subtotal",
      discount: "Discount",
      total: "Total",
      paid: "Paid",
      remainingPayment: "Remaining Payment",
      notFound: "No results found",
      qtyInput: "Qty",
      sum: "Sum",
      netPriceSum: "Net Sum",
      proDiscountPriceSum: "With discount",
      searchSomething: "Use form to search the parts",
      similarPartNumber:
        "Attention! This is not what you exactly search. It is partially similar to your query. Check it twice.",
    },
    SearchForm: {
      brand: "Brand",
      partNumber: "Part Number",
    },
    SignInDialog: {
      title: "Sign In",
      email: "Email",
      password: "Password",
      signInButton: "Sign in",
      signUpButton: "Registration",
      signInSuccess: "Signed in successfully",
      signInError: "Sign in error",
    },
    SignUpDialog: {
      title: "Registration",
      username: "Username",
      email: "Email",
      password: "Password",
      rePassword: "Password confirmation",
      phoneNumber: "Phone number",
      signInButton: "Sign in",
      signUpButton: "Registration",
      signUpSuccess: "Registered successfully",
      signUpSuccessHelper:
        "Confirm your email address using the link from the email",
      signUpError: "Registration error",
    },
    Header: {
      orderHistory: "Order History",
      logOut: "Log out",
    },
  },
  enums: {
    OrderStatusEnum: {
      paymentRequired: "Payment Required",
      pending: "Pending",
      ordered: "Ordered",
      inProgress: "In Progress",
      delivered: "Delivered",
      finished: "Finished",
      dontOrder: "Don't order",
      null: "",
    },
  },
  pages: {
    CreateOrderPage: {
      contactInformation: "Contact information",
      productsInCart: "Products in cart",
      showNetPrice: "Show net prices",
      showProDiscountPrice: "Show discount prices",
    },
    OrderListPage: {
      createdAt: "Created At",
      orderedItems: "Ordered Items",
      total: "Total",
      status: "Status",
      createInvoice: "Create Invoice",
    },
    ViewOrderPage: {
      name: "Name",
      email: "Email",
      phoneNumber: "Phone",
      clientNotes: "Notes",
      createdAt: "Created At",
      updatedAt: "Updated At",
      paid: "Paid",
      remainingPayment: "Remaining Payment",
      status: "Status",
      orderedProducts: "Ordered products",
      showNetPrice: "Show net prices",
      createInvoice: "Create Invoice",
    },
  },
};

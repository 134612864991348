import React, { useContext, useEffect, useState } from "react";
import { Box, Button, MenuItem, Stack, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { AspBrandsEnum, BrandsEnum } from "../typings/enums";
import { SearchParams } from "../typings/interfaces";
import { mapStyles, mergeStyles } from "../libs/styles";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import LoginIcon from "@mui/icons-material/Login";
import useQueryParams from "../hooks/useQueryParams";
import { MpIcon } from "./Icons";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { AppContext } from "../contexts/AppContext";
import { useTranslation } from "react-i18next";
import theme from "../theme";
import { verifyJwt } from "../libs/jwt";

interface SearchFormProps extends React.PropsWithChildren {
  onSearch?: (data: SearchParams) => void;
  isLoading?: boolean;
  sx?: Style;
}

export const SearchForm: React.FC<SearchFormProps> = (props) => {
  const { brand: urlBrand, search: urlSearch } = useQueryParams();
  const { onSearch, isLoading, sx, children } = props;

  const [brand, setBrand] = useState<string>(urlBrand ?? "");
  const [search, setSearch] = useState<string>(urlSearch ?? "");

  const [ctx] = useContext(AppContext);
  const { t } = useTranslation();

  useEffect(() => {
    if ((!!ctx.jwt || !!ctx.token) && !!brand && !!search) {
      handleSearch();
    }
  }, [ctx.jwt, ctx.token]);

  const handleSearch = (e?: any) => {
    e?.preventDefault();
    if (onSearch) {
      onSearch({ brand, search });
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSearch}
      sx={mergeStyles(styles.root, sx)}
    >
      <Stack direction="row" gap={1}>
        {!urlBrand && (
          <TextField
            fullWidth
            select
            label={t("components.SearchForm.brand")}
            SelectProps={{ displayEmpty: true }}
            placeholder={t("components.SearchForm.brand") ?? ""}
            value={brand}
            onChange={(ev) => setBrand(ev.target.value)}
            size="small"
            sx={styles.brands}
          >
            <MenuItem value={BrandsEnum.Bmw}>BMW</MenuItem>
            <MenuItem value={BrandsEnum.Brp}>BRP</MenuItem>
            <MenuItem value={BrandsEnum.Cfmoto}>CFMOTO</MenuItem>
            <MenuItem value={BrandsEnum.Ducati}>Ducati</MenuItem>
            <MenuItem value={BrandsEnum.HarleyDavidson}>
              Harley-Davidson
            </MenuItem>
            <MenuItem value={BrandsEnum.Honda}>Honda</MenuItem>
            <MenuItem value={BrandsEnum.Husqvarna}>Husqvarna</MenuItem>
            <MenuItem value={BrandsEnum.Indian}>Indian</MenuItem>
            <MenuItem value={BrandsEnum.Kawasaki}>Kawasaki</MenuItem>
            <MenuItem value={BrandsEnum.Ktm}>KTM</MenuItem>
            <MenuItem value={BrandsEnum.Linhai}>Linhai</MenuItem>
            <MenuItem value={BrandsEnum.Piaggio}>Piaggio Group</MenuItem>
            <MenuItem value={BrandsEnum.Polaris}>Polaris</MenuItem>
            <MenuItem value={BrandsEnum.Suzuki}>Suzuki</MenuItem>
            <MenuItem value={BrandsEnum.Segway}>Segway</MenuItem>
            <MenuItem value={BrandsEnum.Tgb}>TGB</MenuItem>
            <MenuItem value={BrandsEnum.Triumph}>Triumph</MenuItem>
            <MenuItem value={BrandsEnum.Yamaha}>Yamaha</MenuItem>
          </TextField>
        )}

        <TextField
          autoComplete="off"
          label={`${urlBrand ? brand.toUpperCase() : ""} ${t(
            "components.SearchForm.partNumber"
          )}`}
          fullWidth
          value={search}
          size="small"
          onChange={(ev) => setSearch(ev.target.value)}
        />

        <LoadingButton
          loading={isLoading}
          onClick={handleSearch}
          disabled={!brand || search.length < 3}
          variant="contained"
          size="small"
          type="submit"
        >
          <SearchIcon />
        </LoadingButton>
      </Stack>
    </Box>
  );
};

const styles = mapStyles({
  root: {
    margin: "auto",
    width: "100%",
  },
  brands: {
    minWidth: "130px",
  },
});

export default SearchForm;

import React, { useContext, useMemo } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { mapStyles } from "../libs/styles";
import { AppContext } from "../contexts/AppContext";
import { MpIcon } from "../components/Icons";
import useActualCartData from "../hooks/useActualCartData";
import { CreateOrderFormModel, OrderModel } from "../typings/models";
import DateTime from "../components/DateTime";
import { useTranslation } from "react-i18next";
import { applyMathFormula } from "../libs/mathFormula";

const now = new Date().toISOString();
interface PrintOrderPageProps {
  formData?: Nullable<CreateOrderFormModel>;
  order?: Nullable<Partial<OrderModel>>;
  showDiscount?: boolean;
}
export const PrintOrderPage: React.FC<PrintOrderPageProps> = (props) => {
  const { formData, order, showDiscount } = props;
  const [ctx] = useContext(AppContext);
  const { t, i18n } = useTranslation();

  const { data: cartData, isLoading } = useActualCartData(
    !order ? ctx.cart : []
  );

  const printData: Partial<OrderModel> = useMemo(() => {
    const res = order ?? {
      ...(formData ?? {
        clientName: ctx.user?.username ?? "",
        email: ctx.user?.email ?? "",
        phoneNumber: ctx.user?.phoneNumber ?? "",
      }),
      ...(cartData ?? { order: ctx.cart }),
    };
    return res;
  }, [order, cartData, formData, ctx]);

  return (
    <Box sx={styles.root}>
      <Stack sx={styles.contacts} direction="row" gap={2} alignItems="center">
        <MpIcon sx={styles.mpIcon} />
        <Stack direction="column">
          <Typography>
            {!!printData.id ? (
              <b>#{printData.id}</b>
            ) : (
              <DateTime value={now} component="span" />
            )}
            {printData?.createdAt && (
              <>
                &nbsp;(
                <DateTime value={printData.createdAt} component="span" />
                )&nbsp;
              </>
            )}
            {printData?.status && (
              <span>
                &nbsp;({t(`enums.OrderStatusEnum.${printData?.status}`)})&nbsp;
              </span>
            )}
          </Typography>

          <Typography>
            <b>{printData.clientName}</b>
            &nbsp;&lt;<u>{printData.email}</u>&gt;&nbsp;
            {printData.phoneNumber}
          </Typography>

          {!!printData.clientNotes && (
            <Typography>{printData.clientNotes}</Typography>
          )}

          {!!printData.adminNotes && (
            <Typography>{printData.adminNotes}</Typography>
          )}
        </Stack>
      </Stack>

      <Box sx={styles.tableWrapper}>
        {!!printData && (
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{t("components.ProductList.brand")}:</th>
                <th>{t("components.ProductList.partNumber")}:</th>
                <th>{t("components.ProductList.name")}:</th>
                <th>{t("components.ProductList.price")}:</th>
                <th>{t("components.ProductList.qty")}:</th>
                <th>{t("components.ProductList.sum")}:</th>
              </tr>
            </thead>
            <tbody>
              {printData.order?.map(
                ({ brand, productId, name, price, qty, ...rest }, index) => (
                  <tr key={index}>
                    <td>#{index + 1}</td>
                    <td>{brand?.toUpperCase()}</td>
                    <td>{productId}</td>
                    <td>{name}</td>
                    <td>
                      {showDiscount && printData?.discount?.includes("%") ? (
                        <>
                          <Box component="s" sx={styles.oldPrice}>
                            {price}€
                          </Box>
                          <span>
                            {applyMathFormula(
                              price,
                              printData.discount
                            ).toFixed(2)}
                            €
                          </span>
                        </>
                      ) : (
                        <span>{price}€</span>
                      )}
                    </td>
                    <td>{qty}</td>
                    <td>
                      {showDiscount && printData?.discount?.includes("%") ? (
                        <>
                          <Box component="s" sx={styles.oldPrice}>
                            {(+(qty ?? 0) * +(price ?? 0)).toFixed(2)}€
                          </Box>
                          <span>
                            {applyMathFormula(
                              +(qty ?? 0) * +(price ?? 0),
                              printData.discount
                            ).toFixed(2)}
                            €
                          </span>
                        </>
                      ) : (
                        <span>{(+(qty ?? 0) * +(price ?? 0)).toFixed(2)}€</span>
                      )}
                    </td>
                  </tr>
                )
              )}
            </tbody>
            <tfoot>
              {showDiscount &&
                !!printData.discount &&
                ![null, 0, "0", "-0", "0%", "-0%"].includes(
                  printData.discount
                ) && (
                  <>
                    {!!printData.subtotal && (
                      <tr>
                        <td colSpan={4}></td>
                        <td colSpan={2} style={{ textAlign: "right" }}>
                          <b>{t("components.ProductList.subTotal")}:</b>
                        </td>
                        <td>
                          <b>{printData.subtotal.toFixed(2)}€</b>
                        </td>
                      </tr>
                    )}
                    {showDiscount && !!printData.discount && (
                      <tr>
                        <td colSpan={4}></td>
                        <td colSpan={2} style={{ textAlign: "right" }}>
                          <b>{t("components.ProductList.discount")}:</b>
                        </td>
                        <td>
                          <b>{printData.discount}</b>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              {!!printData.total && (
                <tr>
                  <td colSpan={4}></td>
                  <td colSpan={2} style={{ textAlign: "right" }}>
                    <b>{t("components.ProductList.total")}:</b>
                  </td>
                  <td>
                    <b>
                      {!showDiscount &&
                      !!printData.discount &&
                      !!printData.subtotal
                        ? printData.subtotal.toFixed(2)
                        : printData.total.toFixed(2)}
                      €
                    </b>
                  </td>
                </tr>
              )}
              {!!printData.paid && (
                <tr>
                  <td colSpan={4}></td>
                  <td colSpan={2} style={{ textAlign: "right" }}>
                    <b>{t("components.ProductList.paid")}:</b>
                  </td>
                  <td>
                    <b>{printData.paid}€</b>
                  </td>
                </tr>
              )}
              {!!printData.remainingPayment && (
                <tr>
                  <td colSpan={4}></td>
                  <td colSpan={2} style={{ textAlign: "right" }}>
                    <b>{t("components.ProductList.remainingPayment")}:</b>
                  </td>
                  <td>
                    <b>{printData.remainingPayment}€</b>
                  </td>
                </tr>
              )}
            </tfoot>
          </table>
        )}
      </Box>
    </Box>
  );
};

const styles = mapStyles({
  root: {
    "@media print": {
      fontSize: "12px",
      color: "black",
      background: "white",

      "@page": {
        // marginTop: '3cm',
      },
    },
  },
  contacts: {
    marginBottom: 1,

    "@media print": {
      //   position: 'fixed',
      //   top: '0',
    },
  },
  mpIcon: {
    fontSize: "52px",
  },
  tableWrapper: {
    "& h1, & h2": {
      display: "none",
    },
    "@media print": {
      "& table": {
        // borderCollapse: "collapse !important",
        width: "100%",
        borderSpacing: "1em 0.5em",
        borderCollapse: "separate",

        "& thead th": {
          textAlign: "left",
        },
        "& thead tr": {
          borderBottom: "1px solid grey",
        },
        "& tbody tr": {
          borderBottom: "1px solid grey",
        },
        "& tfoot": {
          marginTop: "1em",
          breakInside: "auto",
        },
        "& tfoot td": {
          py: 0,
        },
        "& td, & th": {
          padding: "0.5em 1em",
          "&:nth-last-of-type(1), &:nth-last-of-type(3)": {
            textAlign: "right",
          },
          "&:nth-last-of-type(2)": {
            textAlign: "center",
          },
        },
      },
    },
  },
  oldPrice: {
    textDecoration: "line-through",
    color: (ct) => ct.palette.grey["600"],
    marginRight: "8px",
    fontSize: "smaller",
  },
});

export default PrintOrderPage;

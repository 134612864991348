import { useContext, useMemo } from "react";
import { decodeJwt } from "jose";
import { AppContext } from "../contexts/AppContext";
import { JWTObject } from "../typings/interfaces";

const getJwtPayload = (jwt: Nullable<string>): JWTObject => {
  if (!jwt) return {} as JWTObject;

  const jwtObj = decodeJwt(jwt) as any as JWTObject;
  if (!jwtObj) return {} as JWTObject;

  return jwtObj;
};

const isJwtValid = (jwt: JWTObject): boolean => {
  if (!jwt || !jwt.exp) return false;
  return jwt.exp * 1000 >= Date.now();
};

const useAuth = () => {
  const [ctx, updateCtx] = useContext(AppContext);

  const signOutFacade = async () => {
    updateCtx({ user: null, jwt: null });
  };

  const { jwtPayload, isValid } = useMemo(() => {
    const jwtObj = getJwtPayload(ctx.jwt);

    return {
      jwtPayload: jwtObj,
      isValid: isJwtValid(jwtObj),
    };
  }, [ctx.jwt]);

  if (!isValid && !ctx.isLoading && (ctx.jwt || ctx.token)) {
    signOutFacade();
  }

  return {
    isSignedIn: ctx.isLoading || (isValid && ctx.user), // should be true while loadings, to avoid unnecessary guard redirect
    isJwtValid: isValid,
    user: ctx.user,
    jwtPayload,
    signOut: signOutFacade,
  };
};

export default useAuth;
